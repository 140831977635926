export const UserOperatorRoles = {
  ROLE_SCANNER: 'ROLE_SCANNER',
  ROLE_ANATOMY: 'ROLE_ANATOMY',
  ROLE_DESIGNER: 'ROLE_DESIGNER',
  ROLE_DESIGN_QC: 'ROLE_DESIGN_QC',
  ROLE_SHIPPER: 'ROLE_SHIPPER',
  ROLE_MANUFACTURING: 'ROLE_MANUFACTURING',
} as const

export type UserOperatorRoles = (typeof UserOperatorRoles)[keyof typeof UserOperatorRoles]
