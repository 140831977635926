import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Navbar } from 'reactstrap'

import { AppRoutes } from 'constants/appRoutes'
import { logout } from 'core/auth'
import NavItem from 'react-bootstrap/NavItem'

export const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [region, setRegion] = useState<string>(window.env('REGION'))

  if (location.pathname === AppRoutes.login) {
    return null
  }

  const handleSelectRegion = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRegion(event.target.value)
    window.setRegion(event.target.value)
    window.location.reload()
  }

  const handleLogoutClick = () => {
    logout()
    navigate(AppRoutes.login)
  }

  return (
    <Navbar color="light" light>
      <Navbar>
        <Link className="navbar-brand" to={AppRoutes.root}>
          Home
        </Link>
        <NavItem className="ml-5">
          Region:
          <select name="region" onChange={handleSelectRegion} value={region}>
            {Object.keys(window.env('REGIONS')).map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        </NavItem>
      </Navbar>
      <NavItem>
        <button onClick={handleLogoutClick}>Logout</button>
      </NavItem>
    </Navbar>
  )
}
