import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, Container } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { ITeroDoctor, ITeroCase, ITeroNotMatchedOrder } from 'domains/itero'
import { useQueryString } from 'hooks/useQueryString'
import { StandardErrorResponse } from 'types/APIResponses'

import {
  ITeroDoctorsTable,
  ITeroNewTable,
  ITeroNoMatchOrdersTable,
  ITeroUploadSection,
} from './components'

const ITeroNavItems = [
  { anchor: '#new', text: 'iTero scan cases in NEW status' },
  { anchor: '#no-match', text: 'Most recent iTero scans - no match' },
  { anchor: '#doctors', text: 'iTero doctors matching' },
  { anchor: '#upload', text: 'Upload scan ZIP' },
] as const

const ITERO_SEARCH_STRING = 'searchCase'
const INITIAL_NOT_MATCHED_LIMIT = '10'
const ITERO_POLLING_TIMEOUT = 10000 //10 sec

export const ITeroPage = () => {
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [search, setSearch] = useQueryString(ITERO_SEARCH_STRING)
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value)

  const [notMatchedLimit, setNotMatchedLimit] = useState(INITIAL_NOT_MATCHED_LIMIT)
  const handleChangeNotMatchedLimit = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNotMatchedLimit(event.target.value)

  const [matches, setMatches] = useState<ITeroCase[]>([])
  const [doctors, setDoctors] = useState<ITeroDoctor[]>([])
  const [notMatchedOrders, setNotMatchedOrders] = useState<ITeroNotMatchedOrder[]>([])

  const handleGetMatches = useCallback(() => {
    API.getITeroMatches()
      .then((response) => {
        setMatches(response)
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGetDoctors = useCallback(() => {
    API.getDoctorsITero()
      .then((response) => {
        setDoctors(response)
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGetNotMatchedOrders = useCallback(() => {
    API.getITeroNotMatchedOrders(notMatchedLimit)
      .then((response) => {
        setNotMatchedOrders(response)
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notMatchedLimit])

  useEffect(() => {
    handleGetNotMatchedOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notMatchedLimit])

  useEffect(() => {
    handleGetMatches()
    handleGetNotMatchedOrders()
    handleGetDoctors()

    const interval = setInterval(() => {
      handleGetMatches()
      handleGetNotMatchedOrders()
    }, ITERO_POLLING_TIMEOUT)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <h2>iTero scan tools</h2>

      <Nav>
        {ITeroNavItems.map(({ anchor, text }, index) => (
          <NavItem key={`${text}-${index}`}>
            <NavLink href={anchor}>{text}</NavLink>
          </NavItem>
        ))}
      </Nav>

      <hr />
      <ITeroNewTable
        searchString={search}
        onChangeSearch={handleChangeSearch}
        matches={matches}
        getMatches={handleGetMatches}
        getDoctors={handleGetDoctors}
      />
      <hr />

      <ITeroNoMatchOrdersTable
        notMatchedOrders={notMatchedOrders}
        onChangeNotMatchedLimit={handleChangeNotMatchedLimit}
        notMatchedLimit={notMatchedLimit}
      />

      <hr />
      <ITeroDoctorsTable doctors={doctors} getDoctors={handleGetDoctors} />
      <hr />

      <ITeroUploadSection
        onGetMatches={handleGetMatches}
        onGetNotMatchedOrders={handleGetNotMatchedOrders}
      />
    </Container>
  )
}
