import { Scan } from 'domains/scan'
import { WithId } from 'types/utility'

import { ScannersReducerActions } from './actions'

export interface ScannersPageState {
  scanners: WithId<Scan>[]
  newScanner: Scan
}

export const initialScannersState: ScannersPageState = {
  scanners: [],
  newScanner: {
    active: false,
    description: '',
    name: '',
    uploadScan: false,
    version: '',
    addScanId: false,
    directTransfer: false,
  },
}

export const scannersPageReducer = (
  state: ScannersPageState,
  action: ScannersReducerActions,
): ScannersPageState => {
  switch (action.type) {
    case 'setScannersResponse':
      return { ...initialScannersState, scanners: action.payload }
    case 'setListScanInput': {
      const { id, value } = action.payload
      const scanners = state.scanners.map((item) => {
        if (item.id === id) {
          return { ...item, ...value }
        }

        return item
      })

      return { ...initialScannersState, scanners }
    }
    case 'setNewScanInput': {
      const newScanner = { ...state.newScanner, ...action.payload }

      return { ...state, newScanner }
    }
    default:
      return state
  }
}
