import React, { useContext, Reducer, useEffect, useReducer, useState } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { Container, Table } from 'reactstrap'

import { API } from 'API'
import { AppRoutes } from 'constants/appRoutes'
import { RequestToasterContext } from 'containers/Providers'
import { Address } from 'domains/address'
import { CaseStatusMatrix } from 'domains/case'
import { ProductInfo } from 'domains/productInfo'
import BigSpinner from 'resources/img/spinner-big.gif'
import { StandardErrorResponse } from 'types/APIResponses'
import { isNotNil } from 'utils/isNotNil'

import { CasesPageContext } from './CasesPageContext'
import { CasesPageActions } from './actions'
import {
  CaseInfo,
  CaseDate,
  CaseStatus,
  CaseShipTracking,
  CaseShipConfirm,
  CaseDoctor,
  CasePatient,
  CaseCancelledInfo,
  CaseProduct,
  CaseTreatArches,
  CaseAlignerMaterial,
  CaseShippingAddresses,
  CasePhotos,
  CaseOfficePrinting,
  MesDesignApproval,
} from './components'
import { CasesPageReducer, CasesPageState, initialCasesPageState } from './reducer'

const CASES_SEARCH_STRING = 'search'

export const CasesPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const { requestStatusRef } = useContext(RequestToasterContext)
  const [search, setSearch] = useState(searchParams.get(CASES_SEARCH_STRING) ?? '')
  const [showSupportToolMaterial, setShowSupportToolMaterial] = useState(false)
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)

  const [casesPageState, dispatchToCasesPageState] = useReducer<
    Reducer<CasesPageState, CasesPageActions>
  >(CasesPageReducer, initialCasesPageState)
  const { userCase, photos } = casesPageState
  const [showMesDesignApprovalFlag, setShowMesDesignApprovalFlag] = useState(false)

  const showMesDesignApproval =
    userCase !== null &&
    (userCase.caseStatus === CaseStatusMatrix.APPROVED_MANUFACTURING || userCase.reorder) &&
    userCase.caseStatus !== CaseStatusMatrix.SHIPPED
  const showCaseShipTracking =
    userCase !== null &&
    (userCase.caseStatus === CaseStatusMatrix.SHIPPED ||
      userCase.caseStatus === CaseStatusMatrix.PARTIALLY_SHIPPED)
  const isOdb = userCase !== null && userCase.productInfoId == ProductInfo.ODB

  useEffect(() => {
    if (search) {
      handleGetCase()
    }
    API.getFeatureFlags().then((data) => {
      setShowSupportToolMaterial(data.isSupportToolMaterialRemoved === false)
      setShowMesDesignApprovalFlag(data.isSupportToolDesignApprovalButtonDisabled === false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isNotNil(userCase?.id)) {
      handleGetShipAddresses()
      handleGetPhotos()
      handleGetPrintCaseId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCase?.id])

  const handlePressSearchKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleGetCase()
    }
  }

  const handleGetCase = () => {
    dispatchToCasesPageState({ type: 'setUserCase', payload: null })

    if (!search) {
      return
    }

    API.getCaseById(Number(search))
      .then((responseCase) => {
        const { treatmentId, id } = responseCase

        dispatchToCasesPageState({ type: 'setUserCase', payload: responseCase })
        navigate({
          pathname: AppRoutes.cases,
          search: `${createSearchParams({ [CASES_SEARCH_STRING]: String(search) })}`,
        })
        API.getCaseStatusRollbackMatrix(id).then((response) => {
          dispatchToCasesPageState({ type: 'setRollback', payload: response })
        })
        if (treatmentId) {
          API.getTreatment(treatmentId).then((response) => {
            dispatchToCasesPageState({ type: 'setTreatment', payload: response })
          })
          API.getMaterials().then((response) => {
            dispatchToCasesPageState({ type: 'setAlignerMaterials', payload: response })
          })
        } else {
          dispatchToCasesPageState({
            type: 'setTreatment',
            payload: { id: null, treatArches: null },
          })
        }

        API.getShippingProviders().then((response) => {
          dispatchToCasesPageState({ type: 'setShippingProviders', payload: response })
        })
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleGetShipAddresses = () => {
    if (!userCase) {
      return
    }

    API.getAccountShipAddresses(userCase.doctor.username).then((response) => {
      const { shipAddress, billAddress } = userCase
      const isShipAddressForSamePractice =
        !shipAddress || userCase.doctor.accountId === shipAddress.accountId
      const isBillAddressForSamePractice =
        !billAddress || userCase.doctor.accountId === billAddress.accountId
      const isBillAndShipAddressSame = shipAddress?.id === billAddress?.id
      const billAndShipAddressesForCase = []

      if (!isShipAddressForSamePractice && shipAddress?.id) {
        billAndShipAddressesForCase.push(shipAddress)
      }

      if (!isBillAddressForSamePractice && billAddress?.id && !isBillAndShipAddressSame) {
        billAndShipAddressesForCase.push(billAddress)
      }
      const allAddresses = [...billAndShipAddressesForCase, ...response]

      dispatchToCasesPageState({ type: 'setAddressData', payload: allAddresses })

      allAddresses.map((item: Address) => {
        if (item?.id) {
          if (shipAddress && shipAddress.id === item.id) {
            dispatchToCasesPageState({
              type: 'setShipAddressId',
              payload: item.id,
            })
          }
          if (billAddress && billAddress.id === item.id) {
            dispatchToCasesPageState({
              type: 'setBillAddressId',
              payload: item.id,
            })
          }
        }
      })
    })
  }

  const handleGetPhotos = () => {
    if (!userCase) {
      return
    }

    API.getCasePhotos(userCase.id).then((response) => {
      dispatchToCasesPageState({ type: 'setCasePhotos', payload: response })
    })
  }

  const handleGetPrintCaseId = () => {
    if (!userCase) {
      return
    }

    API.getCasePrintId({ id: userCase.id, orderIndicator: userCase.orderIndicator }).then(
      (response) => {
        dispatchToCasesPageState({ type: 'setPrintCaseIdData', payload: response })
        response.map((item, index) => {
          if (userCase.id === response[index].id) {
            dispatchToCasesPageState({ type: 'setCasePrintId', payload: response[index].id })
          }
        })
      },
    )
  }

  return (
    <Container>
      <h2>Manage cases</h2>
      <form>
        <input
          type="text"
          name="search"
          value={search}
          onChange={handleSearch}
          onKeyDown={handlePressSearchKey}
          placeholder="Case ID"
          className="w-75"
        />
        <button onClick={handleGetCase} className="m-1" type="button">
          Search
        </button>
      </form>
      {userCase !== null && (
        <CasesPageContext.Provider
          value={{ casesPageState, getCase: handleGetCase, getPhotos: handleGetPhotos }}>
          <Table bordered className="w-75">
            <tbody>
              <CaseInfo />
              <CaseDate />
              <CaseStatus />
              {showMesDesignApprovalFlag && showMesDesignApproval && <MesDesignApproval />}
              {showCaseShipTracking ? <CaseShipTracking /> : <CaseShipConfirm />}
              <CaseDoctor />
              <CasePatient />
              <CaseCancelledInfo />
              <CaseProduct />
              {!isOdb && (
                <>
                  <CaseTreatArches />
                  {showSupportToolMaterial && <CaseAlignerMaterial />}
                </>
              )}
              <CaseShippingAddresses />
              {isNotNil(photos) ? (
                <CasePhotos />
              ) : (
                <tr>
                  <td className="photos_spinner" colSpan={2}>
                    <img src={BigSpinner} alt="spinner" />
                  </td>
                </tr>
              )}
              <CaseOfficePrinting />
            </tbody>
          </Table>
        </CasesPageContext.Provider>
      )}
    </Container>
  )
}
