import { useCallback, useContext } from 'react'
import { Table } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { ITeroDoctor } from 'domains/itero'
import { StandardErrorResponse } from 'types/APIResponses'

import { ITeroDoctorItem } from '../ITeroDoctosItem'

const iTeroDoctorsTableHeaders = [
  'Username',
  'Spark Doctor',
  'iTero Doctor',
  'Created Date',
  ' ',
] as const

interface ITeroDoctorsTableProps {
  doctors: ITeroDoctor[]
  getDoctors: () => void
}

export const ITeroDoctorsTable = ({ doctors, getDoctors }: ITeroDoctorsTableProps) => {
  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleDelete = useCallback((item: ITeroDoctor) => {
    if (!window.confirm('Confirm delete record for "' + item.iteroDoctor + '"')) {
      return
    }
    requestStatusRef.current?.startProgress('Deleting...', 'secondary')
    API.deleteDoctorITero(item.id)
      .then(() => {
        getDoctors()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="doctors">
      <h4>iTero doctors matching</h4>
      <Table size="small">
        <thead>
          <tr>
            {iTeroDoctorsTableHeaders.map((title, index) => (
              <th className="small font-weight-bold" key={`${title}-${index}`}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {doctors.map((doctor, index) => {
            const onDelete = () => handleDelete(doctor)

            return (
              <ITeroDoctorItem key={`${index}-${doctor.id}`} doctor={doctor} onDelete={onDelete} />
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}
