import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { API } from 'API'
import { AppRoutes } from 'constants/appRoutes'
import { RequestToasterContext } from 'containers/Providers'
import { StandardErrorResponse } from 'types/APIResponses'

export const CreateAdminForm = () => {
  const navigate = useNavigate()
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [lastName, setLastName] = useState('')
  const [loginName, setLoginName] = useState('')
  const [isLoginNameGood, setIsLoginNameGood] = useState(true)
  const [isEmailGood, setIsEmailGood] = useState(true)
  const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^[\w-\.]+@([a-zA-Z]+\.)+[a-zA-Z]{2,4}(\s*;\s*[\w-\.]+@([a-zA-Z]+\.)+[a-zA-Z]{2,4})*$/
  const loginNameRegex = /^[a-z0-9-]{1,50}$/
  const handleBackToManageUsers = () => {
    navigate(AppRoutes.users)
  }

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    callback: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const { value } = event.target

    const regex = /^[A-Za-z0-9-]+$/

    if (value === '' || regex.test(value)) {
      callback(value)
    }
    if (callback === setLoginName) {
      setIsLoginNameGood(loginNameRegex.test(value))
    }
  }

  const onEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setEmail(value)
    setIsEmailGood(emailRegex.test(value))
  }

  const shouldDisableCreateButton = () => {
    return !(firstName && email && lastName && loginName)
  }

  const refreshForm = () => {
    setFirstName('')
    setEmail('')
    setLastName('')
    setLoginName('')
    setIsEmailGood(true)
    setIsLoginNameGood(true)
  }

  const handleCreate = () => {
    if (isLoginNameGood && isEmailGood) {
      API.createAdmin({
        username: loginName,
        firstName,
        lastName,
        email,
      })
        .then(() => {
          requestStatusRef.current?.showAlert('Created', 'success')
          refreshForm()
          navigate(`${AppRoutes.users}/${loginName}`)
        })
        .catch((error: StandardErrorResponse) => {
          requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
        })
    }
  }

  return (
    <form className="d-flex flex-wrap">
      <div className="w-50 d-flex flex-column pr-2 pb-2 pt-2">
        <label htmlFor="firstName" className="mr-2">
          First Name
        </label>
        <input
          name="firstName"
          id="firstName"
          value={firstName}
          onChange={(event) => onInputChange(event, setFirstName)}
        />
      </div>
      <div className="w-50 d-flex flex-column pl-2 pb-2 pt-2">
        <label htmlFor="email" className="mr-2">
          Email
        </label>
        <input name="email" id="email" value={email} onChange={onEmailInputChange} />
        {!isEmailGood && (
          <label htmlFor="loginName" className="text-danger">
            Invalid Email Address
          </label>
        )}
      </div>
      <div className="w-50 d-flex flex-column pr-2 pb-2 pt-2">
        <label htmlFor="lastName" className="mr-2">
          Last Name
        </label>
        <input
          name="lastName"
          id="lastName"
          value={lastName}
          onChange={(event) => onInputChange(event, setLastName)}
        />
      </div>
      <div className="w-50 d-flex flex-column pl-2 pb-2 pt-2">
        <label htmlFor="loginName" className="mr-2">
          Login Name
        </label>
        <input
          name="loginName"
          id="loginName"
          value={loginName}
          onChange={(event) => onInputChange(event, setLoginName)}
        />
        {!isLoginNameGood && (
          <label htmlFor="loginName" className="text-danger">
            Login name must only contain up to 50 alphanumeric values in lowercase, and hyphens
          </label>
        )}
      </div>
      <div className="w-50 d-flex align-items-start pr-2 pb-2 pt-4">
        <button onClick={handleBackToManageUsers} className="bg-transparent" type="button">
          Back to Manage users
        </button>
      </div>
      <div className="w-50 d-flex justify-content-end pl-2 pb-2 pt-4">
        <button onClick={handleCreate} type="button" disabled={shouldDisableCreateButton()}>
          Create
        </button>
      </div>
    </form>
  )
}
