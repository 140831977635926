import axios from 'axios'
import { AuthEncodedToken } from 'domains/auth'
import encodeUrl from 'encodeurl'
import decode from 'jwt-decode'
import qs from 'qs'
import { isNotNil } from 'utils/isNotNil'

interface UserLoginCredentials {
  username: string
  password: string
  accountUnlockCode: string
}

export const handleLogin = ({ username, accountUnlockCode, password }: UserLoginCredentials) => {
  const apiLogin = axios.create()
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    ...(accountUnlockCode && { 'x-account-unlock-code': accountUnlockCode }),
  }

  return apiLogin
    .post(
      <string>window.env('LOGIN_URL'),
      qs.stringify({
        grant_type: 'password',
        username: encodeUrl(username),
        password: encodeUrl(password),
        client_id: 'web',
        client_secret: 'secret',
      }),
      {
        headers,
      },
    )
    .then((response) => {
      setToken(response.data.access_token)
      return Promise.resolve(response)
    })
}

export const getUserProfile = () => {
  const token = getToken()

  return isNotNil(token) ? decode<AuthEncodedToken>(token) : null
}

export const getUserAuthorities = () => {
  const userProfile = getUserProfile()

  if (userProfile != null) {
    return userProfile.authorities ?? []
  }
  return []
}

export const setToken = (idToken: string) => {
  localStorage.setItem('id_token', idToken)
}

export const logout = () => {
  localStorage.removeItem('id_token')
}

export const getToken = () => {
  return localStorage.getItem('id_token')
}

export const isLoggedIn = () => {
  const token = getToken()

  return isNotNil(token) && !isTokenExpired(token)
}

export const isTokenExpired = (token: string) => {
  const decoded = decode<AuthEncodedToken>(token)

  return decoded.exp < Date.now() / 1000
}
