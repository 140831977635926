export const ProductInfo = {
  Spark10: '1',
  Spark20: '2',
  SparkAdvanced: '3',
  ODB: '4',
  'Spark Aligners': '5',
  'Spark Passive Aligners': '6',
  Retainers: '7',
  'Spark 15': '8',
  'Spark 25': '9',
  'Spark 35R3': '10',
  'Spark One': '11',
  'Spark Junior Phase 1': '12',
  'Spark Junior Phase 2': '13',
  'Spark Retainers': '14',
  'Spark 35R2': '15',
} as const

export type ProductInfo = keyof typeof ProductInfo
