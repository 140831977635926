import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container } from 'reactstrap'

import { API } from 'API'
import { RequestStatus } from 'components/RequestStatus'
import { UserSearch } from 'components/UserSearch'
import { AppRoutes } from 'constants/appRoutes'
import { UserAccessPermissionsEnum } from 'constants/userAccessPermissionsEnum'
import { RequestToasterContext } from 'containers/Providers'
import { getUserAuthorities } from 'core/auth'
import { FeatureFlags } from 'domains/featureFlags'
import { User } from 'domains/user'
import { StandardErrorResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'
import { isNotNil } from 'utils/isNotNil'

import { UserInfo } from './components'

const USER_PARAMS = { userId: 'userId' } as const

export const UsersPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [username, setUsername] = useState(params[USER_PARAMS.userId] ?? null)
  const [user, setUser] = useState<Nullable<User>>(null)
  const [isCreateAdminAllowed, setIsCreateAdminAllowed] = useState<boolean | undefined>(false)
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({})

  useEffect(() => {
    if (isNotNil(username)) {
      handleGet()
    } else {
      setUser(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  useEffect(() => {
    setIsCreateAdminAllowed(
      getUserAuthorities().includes(UserAccessPermissionsEnum.SUPPORT_TOOL_CREATE_ADMIN),
    )
  }, [])

  const handleGet = () => {
    if (!username) {
      return
    }

    requestStatusRef.current?.startProgress('Getting user info...', 'secondary')
    API.getUser(encodeURIComponent(username))
      .then(({ user, featureFlags }) => {
        setUser(user)
        setFeatureFlags(featureFlags)
        navigate(`${AppRoutes.users}/${user.username}`)
        requestStatusRef.current?.showAlert('Done', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        setUsername(null)
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleNavigateToCreateAdmin = () => {
    if (isCreateAdminAllowed) {
      navigate(AppRoutes.createAdmin)
    }
  }

  return (
    <Container>
      <RequestStatus />
      <h2>Manage users</h2>
      <UserSearch
        rootPath={AppRoutes.users}
        selectedUser={user?.username ?? null}
        onUserSelected={setUsername}
        customAction={
          <button
            onClick={handleNavigateToCreateAdmin}
            disabled={!isCreateAdminAllowed}
            type="button"
            title={
              !isCreateAdminAllowed
                ? 'You are not allowed to perform this action. Please contact your manager to get permission for this action'
                : ''
            }>
            Create Admin User
          </button>
        }
      />
      {isNotNil(user) && <UserInfo user={user} featureFlags={featureFlags} getUser={handleGet} />}
    </Container>
  )
}
