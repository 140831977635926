import React, { useContext, useEffect, useState } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { Container, Table } from 'reactstrap'

import { API } from 'API'
import { AppRoutes } from 'constants/appRoutes'
import { RequestToasterContext } from 'containers/Providers'
import { SystemAccount } from 'domains/account'
import { StandardErrorResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'
import { isNotNil } from 'utils/isNotNil'

import {
  AccountCasePointsAdjustment,
  AccountFieldRep,
  AccountImportDSS,
  AccountImportOFR,
  AccountSalesRep,
  AccountTerritories,
} from './components'

const ACCOUNTS_SEARCH_STRING = 'search'

export const AccountsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [search, setSearch] = useState(searchParams.get(ACCOUNTS_SEARCH_STRING) ?? '')
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)

  const handlePressSearchKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleGetAccounts()
    }
  }

  const [user, setUser] = useState<Nullable<SystemAccount>>(null)

  useEffect(() => {
    if (search) {
      handleGetAccounts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGetAccounts = () => {
    if (!search) {
      setUser(null)
      return
    }
    API.getAccountSearchResults(search)
      .then((response) => {
        setUser(response)
        navigate({
          pathname: AppRoutes.accounts,
          search: `${createSearchParams({ [ACCOUNTS_SEARCH_STRING]: search })}`,
        })
      })
      .catch((error: StandardErrorResponse) => {
        setUser(null)
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <Container>
      <h2>Manage accounts</h2>
      <form>
        <input
          name="search"
          value={search}
          onChange={handleSearch}
          onKeyDown={handlePressSearchKey}
          placeholder="Username"
          className="w-75"
        />
        <button onClick={handleGetAccounts} className="m-1" type="button">
          Search
        </button>
      </form>
      {isNotNil(user) && (
        <Table bordered className="w-75">
          <tbody>
            <tr>
              <td>Doctor userid</td>
              <td>{user.username}</td>
            </tr>
            <AccountSalesRep
              username={user.username}
              initSalesRep={user.account.salesRep?.username ?? ''}
            />
            <AccountFieldRep
              username={user.username}
              initFieldRep={user.account.fieldRep?.username ?? ''}
            />
            <AccountTerritories
              username={user.username}
              initTerritoryId={user.account.territoryId ?? ''}
            />
            <AccountCasePointsAdjustment
              username={user.username}
              initAdjustment={user.adjustment ?? '0'}
            />
          </tbody>
        </Table>
      )}
      <AccountImportDSS />
      <AccountImportOFR />
    </Container>
  )
}
