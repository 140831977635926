import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Nav, NavItem } from 'reactstrap'

import { AppRoutes } from 'constants/appRoutes'
import { getUserProfile } from 'core/auth'
import { UserRoles, UserOperatorRoles } from 'domains/user'

const navLinks = [
  { route: AppRoutes.cases, title: 'Cases' },
  { route: AppRoutes.users, title: 'Users' },
  { route: AppRoutes.accounts, title: 'Accounts' },
  { route: AppRoutes.scanners, title: 'Scanners' },
  { route: AppRoutes.supports, title: 'Supports' },
  { route: AppRoutes.itero, title: 'iTero' },
  { route: AppRoutes.webcontent, title: 'WebContent' },
  { route: AppRoutes.pvs, title: 'PVS' },
  { route: AppRoutes.transfer, title: 'Transfer Cases' },
  { route: AppRoutes.addresses, title: 'Manage addresses' },
]

export const NavPage = () => {
  const navigate = useNavigate()
  const user = getUserProfile()

  useEffect(() => {
    if (user?.authorities.includes(UserOperatorRoles.ROLE_SCANNER) === true) {
      document.title = 'Support Scan Toolset'
      navigate(AppRoutes.scans)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {user?.authorities.includes(UserRoles.ROLE_USER_ADMIN) === true && (
        <Container>
          <h1>Support Admin Toolset</h1>
          <Nav vertical>
            {navLinks.map(({ title, route }, index) => (
              <NavItem key={`${index}-${title}`}>
                <Link className="nav-link" to={route}>
                  {title}
                </Link>
              </NavItem>
            ))}
          </Nav>
        </Container>
      )}
      {user?.authorities.includes(UserOperatorRoles.ROLE_SCANNER) === true && (
        <Container>
          <h1>Scan Operator Toolset</h1>
          <Nav vertical>
            <NavItem>
              <Link className="nav-link" to={AppRoutes.scanners}>
                Scans
              </Link>
            </NavItem>
          </Nav>
        </Container>
      )}
    </>
  )
}
