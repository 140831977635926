import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Container } from 'reactstrap'

import { API } from 'API'
import { AppRoutes } from 'constants/appRoutes'
import { handleLogin, isLoggedIn } from 'core/auth'
import { useEffectOnce } from 'hooks/useEffectOnce'
import BigSpinner from 'resources/img/spinner-big.gif'
import { StandardErrorResponse } from 'types/APIResponses'

import { LockedAccount, LoginSSO, LoginForm } from './components'
import { errorMap } from './constants'

export const LoginPage = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const code = searchParams.get('code') ?? ''
  const redirectUri = `${window.location.origin}/login`

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoginSSO, setIsLoginSSO] = useState<boolean>(false)
  const [SSOLoginError, setSSOLoginError] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [accountUnlockCode, setAccountUnlockCode] = useState('')
  const [isAccountLocked, setIsAccountLocked] = useState(false)
  const [isFormButtonVisible, setIsFormButtonVisible] = useState(false)

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(event.target.value)

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value)

  const handleChangeAccountUnlockCode = (event: React.ChangeEvent<HTMLInputElement>) =>
    setAccountUnlockCode(event.target.value)

  const handleToggleLoginType = (event: React.MouseEvent) => {
    event.preventDefault()
    setIsLoginSSO((prevValue) => !prevValue)
    setIsFormButtonVisible(false)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleLogin({ username, accountUnlockCode, password })
      .then(() => {
        navigate(AppRoutes.root)
        window.location.reload()
      })
      .catch((error: StandardErrorResponse) => {
        switch (error.response.data.error_description) {
          case errorMap.lockedAccount:
            setIsAccountLocked(true)
            break
          case errorMap.notAuthorized:
            alert(error.response.data.error_description)
            setIsFormButtonVisible(true)
            break
          default:
            alert(error.response.data.error_description)
        }
      })
  }

  const handleRedirectToLoginSSO = async () => {
    if (!isLoggedIn()) {
      await API.getSSOAuthenticationFormUrl(redirectUri)
    }
  }

  const SSOAuthenticate = () => {
    if (!isLoggedIn() && code) {
      API.getSSOAuthenticate({ redirectUri, code })
        .then(() => {
          navigate(AppRoutes.root)
        })
        .catch((error: StandardErrorResponse) => {
          if (typeof error.response.data.error_description === 'string') {
            setSSOLoginError(error.response.data.error_description)
          } else {
            alert(error.response.data.error)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffectOnce(() => {
    API.getFeatureFlags()
      .then((data) => {
        if (data.supportToolAzureAD === true) {
          SSOAuthenticate()
          setIsLoginSSO(true)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  })

  useEffect(() => {
    const paramsSize = (searchParams as unknown as { size: number }).size

    if (!isLoading && paramsSize > 0 && (isAccountLocked || !isLoginSSO)) {
      setSearchParams({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, searchParams, isAccountLocked, isLoginSSO])

  if (isAccountLocked) {
    return <LockedAccount onCodeChange={handleChangeAccountUnlockCode} onSubmit={handleSubmit} />
  }

  return (
    <Container className="w-50 mt-5">
      <h2>Sign In</h2>
      {isLoading && (
        <img src={BigSpinner} className="d-block m-auto" height="100px" alt="spinner" />
      )}

      {!isLoading && isLoginSSO && (
        <LoginSSO
          error={SSOLoginError}
          onChangeLoginType={handleToggleLoginType}
          onSubmit={handleRedirectToLoginSSO}
        />
      )}

      {!isLoading && !isLoginSSO && (
        <LoginForm
          isBackButtonVisible={isFormButtonVisible}
          onChangeLoginType={handleToggleLoginType}
          onUserNameChange={handleChangeUsername}
          onPasswordChange={handleChangePassword}
          onSubmit={handleSubmit}
        />
      )}
    </Container>
  )
}
