import React, { useEffect, useState } from 'react'

import { getUserProfile, isLoggedIn } from 'core/auth'
import { AuthEncodedToken } from 'domains/auth'

export const Principal = () => {
  const [token, setToken] = useState<AuthEncodedToken | null>(null)

  useEffect(() => {
    if (isLoggedIn()) {
      const profileToken = getUserProfile()

      setToken(profileToken)
    }
  }, [])

  return (
    <span>
      {token ? (
        <>
          User: {token.user_name}
          <br />
          Authorities: {token.authorities.join(', ')}
        </>
      ) : (
        'Not logged in'
      )}
    </span>
  )
}
