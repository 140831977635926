import React from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

import { AppRoutes } from 'constants/appRoutes'
import { UserAccessPermissionsEnum } from 'constants/userAccessPermissionsEnum'
import { getUserAuthorities, isLoggedIn } from 'core/auth'
interface PrivateRouteProps {
  permission?: UserAccessPermissionsEnum
}
export const PrivateRoute = ({ permission }: PrivateRouteProps) => {
  const isAuthenticated = isLoggedIn()
  const navigate = useNavigate()

  if (permission != undefined) {
    const hasPermissions = getUserAuthorities().includes(permission)

    if (!isAuthenticated) {
      navigate(AppRoutes.login)
    }
    return hasPermissions ? <Outlet /> : <Navigate to={AppRoutes.forbidden} />
  }
  return isAuthenticated ? <Outlet /> : <Navigate to={AppRoutes.login} />
}
