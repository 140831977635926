import { useState, useCallback } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

export const useQueryString = (key: string): [string, (value: string) => void] => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)

  const [value, setValue] = useState(searchParams.get(key) ?? '')
  const onSetValue = useCallback(
    (newValue: string) => {
      setValue(newValue)

      navigate({ search: `${createSearchParams({ [key]: newValue })}` })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key],
  )

  return [value, onSetValue]
}
