export const convertFileSizeToHumanReadable = (bytes: number, isSI = true, decimalPlaces = 1) => {
  const threshHold = isSI ? 1000 : 1024

  if (Math.abs(bytes) < threshHold) {
    return bytes + ' B'
  }
  const units = isSI
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let unit = -1
  const range = 10 ** decimalPlaces

  do {
    bytes /= threshHold
    ++unit
  } while (Math.round(Math.abs(bytes) * range) / range >= threshHold && unit < units.length - 1)
  return bytes.toFixed(decimalPlaces) + ' ' + units[unit]
}
