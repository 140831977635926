import { useFormContext } from 'react-hook-form'

import {
  FeatureFlagsSchemaEntry,
  FeatureFlagValue,
  GenericFeatureFlags,
} from 'domains/featureFlags'

import { FeatureFlagControl } from '../FeatureFlagControl'

interface FeatureFlagItemProps {
  path: string
  ffSchemaItem: FeatureFlagsSchemaEntry
  dbFFItem?: FeatureFlagValue | FeatureFlagValue[]
  nested?: boolean
}

export const FeatureFlagItem = ({ path, ffSchemaItem, dbFFItem, nested }: FeatureFlagItemProps) => {
  const form = useFormContext<GenericFeatureFlags>()

  const isVisible = !ffSchemaItem.dependentOn || form.watch(ffSchemaItem.dependentOn)

  if (!isVisible) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const shiftedItem = nested || Boolean(ffSchemaItem.dependentOn)
  const padding = shiftedItem ? 'ml-4' : ''

  return (
    <div className={padding}>
      <FeatureFlagControl path={path} ffSchemaItem={ffSchemaItem} dbFFItem={dbFFItem} />
    </div>
  )
}
