import React, { useContext } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { Scan } from 'domains/scan'
import { StandardErrorResponse } from 'types/APIResponses'
import { WithId } from 'types/utility'

interface ScannerItemActionsProps {
  scanner: WithId<Scan>
  getScanners: () => void
}

export const ScannerItemActions = ({ scanner, getScanners }: ScannerItemActionsProps) => {
  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleDelete = () => {
    const { id, name } = scanner

    if (!window.confirm('Confirm delete scanner "' + name + '"')) {
      return
    }
    requestStatusRef.current?.startProgress('Deleting...', 'secondary')
    API.DeleteScanner(id)
      .then(() => {
        requestStatusRef.current?.showAlert('Deleted', 'success')
        getScanners()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleUpdate = () => {
    requestStatusRef.current?.startProgress('Updating...', 'secondary')
    API.updateScannerById(scanner)
      .then(() => {
        requestStatusRef.current?.showAlert('Updated', 'success')
        getScanners()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <>
      <button onClick={handleDelete} className="float-right mr-2" type="button">
        Delete
      </button>
      <button onClick={handleUpdate} className="float-right mr-2" type="button">
        Update
      </button>
    </>
  )
}
