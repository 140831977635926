import { Case, CasePhoto, CaseScan, CaseStatusRollback } from 'domains/case'
import { ProductInfo } from 'domains/productInfo'
import { UserGender } from 'domains/user'
import { EmptyAPIResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'
import { parseFileToForm } from 'utils/parseFileToFormData'

import { fileApi, protectedApi } from './requester'

export const getCasesByUsername = (username: string) => {
  return protectedApi.get<Case[]>(`/cases?username=${username}`).then((response) => response.data)
}

export const getCaseById = (id: number) => {
  return protectedApi.get<Case>(`/case/${id}`).then((response) => response.data)
}

export const getCaseStatusRollbackMatrix = (caseId: number) => {
  return protectedApi
    .get<CaseStatusRollback>(`/caseStatusRollbackMatrix`, {
      params: {
        caseId,
      },
    })
    .then((response) => response.data)
}

export const getCasePhotos = (id: number) => {
  return protectedApi
    .get<Record<string, CasePhoto>>(`/case/${id}/photo`)
    .then((response) => response.data)
}

interface UpdateCaseByIdOptions {
  id: number
  cancelled?: boolean
  productInfoId?: Nullable<(typeof ProductInfo)[keyof typeof ProductInfo]>
  rollbackStatus?: boolean
  rollbackNotes?: string
  rollbackSendEmail?: boolean
  treatArches?: string
  username?: string
  partialShip?: boolean
  endBatchCount?: string
  shippingProvider?: string
  shippingTrackingNumber?: Nullable<string>
  shipAddressId?: number
  billAddressId?: number
}

interface TransferCasesRequestDto {
  toUsername: string
  publicCaseIds: number[]
}

export const transferCases = ({ toUsername, publicCaseIds }: TransferCasesRequestDto) => {
  return protectedApi
    .post<string | void>('cases/transfer', { toUsername, publicCaseIds })
    .then(({ data }) => data)
    .catch((error) => {
      if (Boolean(error.response) && Boolean(error.response.data)) {
        if ('error_description' in error.response.data) return error.response.data.error_description
        if ('error' in error.response.data) return error.response.data.error
      }
      return error?.message ?? 'Some error occurred...'
    })
}

export const updateCaseById = ({ id, ...params }: UpdateCaseByIdOptions) => {
  return protectedApi.put<EmptyAPIResponse>(
    `case/${id}`,
    {},
    {
      params,
    },
  )
}

export const deleteComboTreatmentByParentId = (id: number) => {
  return protectedApi.delete<EmptyAPIResponse>(`case/${id}/childComboTreatment`)
}

interface UpdateCasePatientNameOptions {
  id: number
  firstName?: string
  lastName?: string
  birthDate?: string
  gender?: UserGender
}

export const updateCasePatientById = ({ id, ...params }: UpdateCasePatientNameOptions) => {
  return protectedApi.put<EmptyAPIResponse>(`case/${id}/patient`, {}, { params })
}

interface UploadCasePhotoOptions {
  id: number
  item: string
  file: File
}

export const uploadCasePhoto = ({ id, item, file }: UploadCasePhotoOptions) => {
  return fileApi.post<EmptyAPIResponse>(`case/${id}/photo/${item}`, parseFileToForm(file))
}

interface DeleteCasePhotoOptions {
  id: number
  item: string
}

export const deleteCasePhoto = ({ id, item }: DeleteCasePhotoOptions) => {
  return protectedApi.delete<EmptyAPIResponse>(`case/${id}/photo/${item}`)
}

export const fixCasePhotos = (id: number) => {
  return protectedApi.post<EmptyAPIResponse>(`case/${id}/fix-photos`)
}

interface UploadCaseScanOptions {
  id: number
  file: File
}

export const getCaseScans = (caseId: number) => {
  return protectedApi.get<CaseScan[]>(`case/${caseId}/scan`).then((response) => response.data)
}

export const uploadCaseScan = ({ id, file }: UploadCaseScanOptions) => {
  return fileApi
    .post<EmptyAPIResponse>(`case/${id}/scan`, parseFileToForm(file))
    .then((response) => response.data)
}

interface DeleteCaseScanOptions {
  id: number
  pathToFile: string
}

export const deleteCaseScan = ({ id, pathToFile }: DeleteCaseScanOptions) => {
  return protectedApi.delete<EmptyAPIResponse>(`case/${id}/scan/${pathToFile}`)
}

interface GetCasePrintIdOptions {
  id: number
  orderIndicator: Nullable<string>
}

export const getCasePrintId = ({ id, orderIndicator }: GetCasePrintIdOptions) => {
  return protectedApi
    .get<Case[]>(`case/${id}/orderList?orderIndicator=${orderIndicator}`)
    .then((response) => response.data)
}

interface PrintCaseResponseOptions {
  casePrintId: number
  file: File
}

export const printCaseResponse = ({ casePrintId, file }: PrintCaseResponseOptions) => {
  return fileApi.post<EmptyAPIResponse>(`case/${casePrintId}/print-response`, parseFileToForm(file))
}

interface ConfirmCaseShipOptions {
  id: number
  shippingProvider: string
  trackingNumber: string
  salesOrderNumber: string
  source: string
  noEmail: boolean
  noResponse: boolean
  noMes: boolean
}

export const confirmCaseShip = ({ id, ...params }: ConfirmCaseShipOptions) => {
  return protectedApi.post<EmptyAPIResponse>(
    `case/${id}/shipConfirm`,
    {},
    {
      params,
    },
  )
}

interface ToggleErpOptions {
  id: number
  erpSoEnabled: boolean
}

export const toggleErp = ({ id, erpSoEnabled }: ToggleErpOptions): EmptyAPIResponse => {
  return protectedApi.put(`case/${id}?erpSoEnabled=${erpSoEnabled}`)
}

export const mesDesignApproval = (id: number): EmptyAPIResponse => {
  return protectedApi.post(`case/${id}/mesDesignApproval`)
}
