import { FC, useContext, useState, useEffect } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { FeatureFlagsContext } from 'domains/featureFlags'
import { StandardErrorResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'

import { PermissionsFormItem } from '../common'

interface PermissionsContextProps {
  username: string
  application: string
}
export const PermissionsContext: FC<PermissionsContextProps> = ({ username, application }) => {
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [contextData, setContextData] = useState<Nullable<FeatureFlagsContext>>()

  useEffect(() => {
    handleGet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, application])

  const handleGet = () => {
    if (!username || !application) {
      return
    }

    requestStatusRef.current?.startProgress('Getting permissions info...', 'secondary')
    API.getUsersFeatureFlagsContext(encodeURIComponent(username), application)
      .then((ffContext) => {
        setContextData(ffContext)
        requestStatusRef.current?.showAlert('Done', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        setContextData(null)
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  if (!contextData) {
    return <div>Loading...</div>
  }

  return (
    <div className="w-75 border p-3 mb-3 bg-light">
      <p style={{ fontWeight: 600 }}>
        {contextData.userFirstName} {contextData.userLastName}
      </p>
      <div className="d-flex">
        <div className="w-50">
          <PermissionsFormItem name="Username" value={contextData.username} />
          <PermissionsFormItem name="Customer ID" value={contextData.customerId} />
          <PermissionsFormItem name="Account" value={contextData.accountName} />
          <PermissionsFormItem name="DSO" value={contextData.organization} />
        </div>
        <div className="w-50">
          <PermissionsFormItem name="Region" value={contextData.region} />
          <PermissionsFormItem name="Country" value={contextData.country} />
          <PermissionsFormItem name="ISO Code" value={contextData.isoCode} />
          <PermissionsFormItem name="Application" value={application} />
        </div>
      </div>
    </div>
  )
}
