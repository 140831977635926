import React, { useContext, useEffect, useState } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'

import { API } from 'API'
import { AppRoutes } from 'constants/appRoutes'
import { RequestToasterContext } from 'containers/Providers'
import { Case, CaseScan } from 'domains/case'
import { Treatment } from 'domains/treatment'
import { StandardErrorResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'
import { isNotNil } from 'utils/isNotNil'

import { ScansPageContent } from './components'

const SCANS_SEARCH_STRING = 'search'

export const ScansPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [searchCaseId, setSearchCaseId] = useState(searchParams.get(SCANS_SEARCH_STRING) ?? '')
  const handleChangeSearchId = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchCaseId(event.target.value)
  const handlePressSearchKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleGetSearchCase()
    }
  }

  const [treatmentCase, setTreatmentCase] = useState<Nullable<Case>>(null)
  const [treatment, setTreatment] = useState<Nullable<Treatment>>(null)
  const [scans, setScans] = useState<CaseScan[]>([])

  const handleSetInitialState = () => {
    setTreatmentCase(null)
    setTreatment(null)
    setScans([])
  }

  useEffect(() => {
    if (searchCaseId) {
      handleGetSearchCase()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGetSearchCase = () => {
    if (!searchCaseId) {
      return
    }
    API.getCaseById(Number(searchCaseId))
      .then((caseResponse) => {
        setTreatmentCase(caseResponse)
        if (caseResponse.treatmentId) {
          API.getTreatment(caseResponse.treatmentId).then((treatment) => {
            setTreatment(treatment)
          })
        }

        navigate({
          pathname: AppRoutes.scans,
          search: `${createSearchParams({ [SCANS_SEARCH_STRING]: searchCaseId })}`,
        })
        handleGetScans()
      })
      .catch((error: StandardErrorResponse) => {
        handleSetInitialState()
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleGetScans = () => {
    if (!treatmentCase) {
      return
    }

    API.getCaseScans(treatmentCase.id)
      .then((response) => {
        setScans(response)
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <Container>
      <h2>Manage scans</h2>
      <form>
        <input
          name="search"
          value={searchCaseId}
          onChange={handleChangeSearchId}
          onKeyDown={handlePressSearchKey}
          placeholder="Case ID"
          className="w-75"
        />
        <button onClick={handleGetSearchCase} className="m-1" type="button">
          Search
        </button>
      </form>
      {isNotNil(treatmentCase) && (
        <ScansPageContent treatmentCase={treatmentCase} treatment={treatment} scans={scans} />
      )}
    </Container>
  )
}
