import React, { Reducer, useContext, useEffect, useReducer, useState } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'

import { API } from 'API'
import { AppRoutes } from 'constants/appRoutes'
import { Languages } from 'constants/languages'
import { RequestToasterContext } from 'containers/Providers'
import { StandardErrorResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'
import { isNotNil } from 'utils/isNotNil'

import { PVSReducerActions } from './actions'
import { PVSPageContent } from './components'
import { PVSReducer, PVSState } from './reducer'

const PVSPageSearchParams = {
  case: 'searchCaseId',
  jiraTicker: 'searchTicket',
  language: 'emailLanguage',
} as const

const PVSProcess = [
  'Search for case and ticket',
  'Review email addresses',
  'Review/update subject and content of the email',
  'Review Jira ticket',
  'Review attachments, remove those too big to be sent by email',
  'Click "Send" email to doctor with attachments',
  'Place the case on Hold with hold reason as "Bad Impression"',
  'Rollback the case to NEW state',
  'New shipping label will be automatically sent to doctor within an hour',
] as const

export const PVSPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [searchCaseId, setSearchCaseId] = useState(searchParams.get(PVSPageSearchParams.case) ?? '')
  const handleChangeSearchId = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchCaseId(event.target.value)

  const [searchTicket, setSearchTicket] = useState(
    searchParams.get(PVSPageSearchParams.jiraTicker) ?? '',
  )
  const handleChangeSearchTicket = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTicket(event.target.value)

  const [searchEmailLanguage, setSearchEmailLanguage] = useState(
    searchParams.get(PVSPageSearchParams.language) ?? 'preferred',
  )
  const handleChangeEmailLanguage = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setSearchEmailLanguage(event.target.value)

  const [pageState, dispatchToPageState] = useReducer<
    Reducer<Nullable<PVSState>, PVSReducerActions>
  >(PVSReducer, null)

  const handleSearch = () => {
    if (!(searchCaseId.trim() && searchTicket.trim())) {
      requestStatusRef.current?.showAlert('Enter case ID and ticket', 'danger')
      return
    }

    requestStatusRef.current?.startProgress('Searching...', 'secondary')
    API.searchPVS({
      searchCaseId,
      searchTicket,
      emailLanguage: searchEmailLanguage,
    })
      .then((response) => {
        requestStatusRef.current?.showAlert('Done', 'success')
        dispatchToPageState({
          type: 'setPVSState',
          payload: {
            id: response.treatmentCase.id,
            originalCaseId: response.treatmentCase.originalCaseId,
            orderIndicator: response.treatmentCase.orderIndicator,
            doctor: response.treatmentCase.doctor,
            summary: response.ticket.summary,
            description: response.ticket.description,
            attachments: response.ticket.attachments,
            reorder: response.treatmentCase.reorder,
            doctor_email: response.toEmails ?? '',
            emailSubject: response.emailSubject ?? '',
            emailContent: response.emailContent ?? '',
            pvsTicketBcc: response.pvsTicketBcc,
          },
        })
        navigate({
          pathname: AppRoutes.pvs,
          search: `${createSearchParams({
            [PVSPageSearchParams.case]: searchCaseId,
            [PVSPageSearchParams.jiraTicker]: searchTicket,
            [PVSPageSearchParams.language]: searchEmailLanguage,
          })}`,
        })
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response?.data.error, 'danger')
      })
  }

  useEffect(() => {
    if (searchCaseId && searchTicket) {
      handleSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <h2>PVS Exceptions</h2>
      <div>Enter Case ID, Ticket and Email Language</div>
      <form className="m-1">
        <input
          name="searchCaseId"
          value={searchCaseId}
          onChange={handleChangeSearchId}
          placeholder="Case ID"
          className="w-25 mr-2"
        />
        <input
          name="searchTicket"
          value={searchTicket}
          onChange={handleChangeSearchTicket}
          placeholder="Jira Ticket"
          className="w-25 mr-2"
        />
        <select
          name="emailLanguage"
          value={searchEmailLanguage}
          onChange={handleChangeEmailLanguage}
          className="mr-2">
          <option value="preferred">Doctor Preferred Language</option>
          {Object.entries(Languages).map(([key, value], index) => (
            <option key={`${key}-${index}`} value={key}>
              {value}
            </option>
          ))}
        </select>
        <button onClick={handleSearch} className="m-1" type="button">
          Search
        </button>
      </form>
      <div
        className="w-25 border p-2 mt-4 bg-light small"
        style={{ position: 'absolute', top: '5em', right: '1em' }}>
        <h6>Follow the process:</h6>
        <ol>
          {PVSProcess.map((processCase) => (
            <li key={processCase}>{processCase}</li>
          ))}
        </ol>
      </div>

      {isNotNil(pageState) && (
        <PVSPageContent
          pvsCase={pageState}
          dispatchToPageState={dispatchToPageState}
          searchTicket={searchTicket}
        />
      )}
    </Container>
  )
}
