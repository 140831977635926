import { EffectCallback, useEffect, useRef } from 'react'

export const useEffectOnce = (effect: EffectCallback) => {
  const effectCalled = useRef(false)

  useEffect(() => {
    if (effectCalled.current) return

    effectCalled.current = true
    return effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
