import React, { useContext, useEffect, useState } from 'react'
import { Container, ListGroup } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { Support } from 'domains/support'
import { StandardErrorResponse } from 'types/APIResponses'

import { SupportItem } from './compoenents'

export const SupportsPage = () => {
  const { requestStatusRef } = useContext(RequestToasterContext)
  const [supports, setSupports] = useState<Support[]>([])

  useEffect(() => {
    getSupports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSupports = () => {
    API.getSupports()
      .then((response) => {
        setSupports(response)
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <Container>
      <h2>Supports</h2>
      <ListGroup className="w-75">
        {supports.map((support, index) => (
          <SupportItem key={`${support.id}-${index}`} support={support} getSupports={getSupports} />
        ))}
      </ListGroup>
    </Container>
  )
}
