import React, { useContext, useEffect, useState } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers/RequestToasterProvider'
import { AccountTerritory } from 'domains/account'
import { StandardErrorResponse } from 'types/APIResponses'

interface AccountTerritoriesProps {
  username: string
  initTerritoryId: string
}

export const AccountTerritories = ({ username, initTerritoryId }: AccountTerritoriesProps) => {
  const [territories, setTerritories] = useState<AccountTerritory[]>([])
  const [territoryId, setTerritoryId] = useState(initTerritoryId)
  const handleChangeTerritoryId = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setTerritoryId(event.target.value)

  const { requestStatusRef } = useContext(RequestToasterContext)

  useEffect(() => {
    API.getAccountTerritory()
      .then((response) => {
        setTerritories(response)
      })
      .catch((error: StandardErrorResponse) => {
        setTerritories([])
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateTerritoryId = () => {
    API.updateAccountParams({
      username,
      territoryId,
    })
      .then(() => requestStatusRef.current?.showAlert('Updated', 'success'))
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Territory ID</td>
      <td>
        <form>
          <select
            name="territoryId"
            value={territoryId}
            onChange={handleChangeTerritoryId}
            className="w-75">
            <option value="">NULL</option>
            {territories.map((item: AccountTerritory, index: number) => (
              <option key={`${index}-${item.id}`} value={item.id}>
                {item.location} ({item.number})
              </option>
            ))}
          </select>
          <button onClick={handleUpdateTerritoryId} className="float-right" type="button">
            Update
          </button>
        </form>
      </td>
    </tr>
  )
}
